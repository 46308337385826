.shelvesList {display: flex; padding: 0; margin: 0; flex-wrap: wrap-reverse;
    li {position: relative; display: flex; width: 100%; padding: 5px 60px; border-bottom: 10px solid rgba(#d9d9d9,50%); flex-direction: row-reverse; counter-increment: shelves;
        &:before, &:after {position: absolute; width: 55px; height: 80px; top: 0; font-size: 4rem; font-weight: bolder; color: rgba(#000,40%); text-align: center; content: counter(shelves);}
        &:before {right: 0; border-left: 10px solid rgba(#d9d9d9,50%);}
        &:after {left: 0; border-right: 10px solid rgba(#d9d9d9,50%);}
        div {display: flex; width: 60px; height: 70px; margin-left: 2px; background: #dadada; font-size: 2rem; font-weight: bolder; color: white; justify-content: center; align-items: center; cursor: pointer;
            &:last-child {margin-left: 0;}
            &.nobles {background: #eabfff!important;}
            &.grande-nobles {background: #bbb !important;}
            &.ecosy {background: rgb(215, 0, 64) !important;}
            &.eclips {background: #049a5b !important;}
            &.torus {background: rgb(0, 158, 247) !important;}
            &.vision {background: rgb(255, 199, 0) !important;}
            &.massiv {background: #ffc0cb!important;}
            &.max {background: #FF7518 !important;}
            &.free {background: #32fca7 !important;}
            &.inactive {background: repeating-linear-gradient(135deg, #e34646 0, #e34646 10%, transparent 0, transparent 50%);
                background-size: 0.5em 0.5em;
                background-color: #FFf;
                opacity: 1;
                color: transparent;
                
            }
            &.others {background: #C19A6B !important;}
            &.forSearch {background: black !important;}
        } 
    }
    &.mirrored li {flex-direction: row;
        div {margin-right: 2px; margin-left: 0;}
    }
}

li.found {position: relative;
    &:after {position: absolute; width: 5px; height: 5px; top: 5px; right: 10px; background-color: black; border-radius: 50%; content: "";}
}