// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';

#root {
  display: contents;
}

.barcode {font-family: "Libre Barcode 128 Text"; font-size: 115px; line-height: 140px; text-align: right;}

.h-140px {height: 140px !important;}